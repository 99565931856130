import FontFaceObserver from 'fontfaceobserver'

export default function watchFonts() {
  const fonts: { [key: string]: string } = {
    'material-icons': 'Material Icons',
    'material-icons-outlined': 'Material Icons Outlined',
  }

  Promise.all(
    Object.keys(fonts)
      .map((key) => awaitFont({ key, name: fonts[key] })),
  ).then(
    () => document.documentElement.className += ' fonts-loaded',
  ).catch(
    (err: any) => console.error(`One or more fonts failed to load:`, err),
  )
}

function awaitFont({key, name}: { key: string, name: string }) {
  const loading = `font-${key}-loading`
  const root = document.documentElement
  document.documentElement.className += ' ' + loading
  const observer = new FontFaceObserver(name)

  observer.load()
    .then(
      () => {
        root.className = root.className.replace(loading, '')
        root.className += ` font-${key}-loaded`
      },
    )
    .catch(
      (ex: any) => {
        root.className = root.className.replace(loading, '')
        root.className += ` font-${key}-failed`
        console.error(`Font ${name} failed to load`, ex)
      },
    )
}
